import { Injectable } from '@angular/core';
import { UrlEnum } from '../../enum/url.enum';
import { UtilitiesService } from '../utilities/utilities.service';
import { HttpRequestService } from '../http-request/http-request.service';
import { map, Observable } from 'rxjs';
import { CampaignModel } from '../../model/campaign.model';

@Injectable({
  providedIn: 'root',
})
export class CampaignsService {
  private campaignApi = `${UrlEnum.SHOHAY_CAMPAIGN}/campaign`;

  constructor(
    private readonly utilitiesService: UtilitiesService,
    private readonly httpRequestService: HttpRequestService
  ) {}

  getAllCampaigns(): Observable<any> {
    return this.utilitiesService
      .responseHandlerWithAlert(
        this.httpRequestService.get(`${this.campaignApi}/get-all`, {
          skipAuth: true,
        })
      )
      .pipe(
        map((campaigns: any[]) =>
          campaigns.map((campaign: any) => ({
            ...campaign,
            img_url: JSON.parse(campaign.img_url).sort(
              this.utilitiesService.dynamicSort('order')
            ),
          }))
        )
      );
  }

  public createCampaign(body: CampaignModel): Observable<any> {
    const url = `${this.campaignApi}/create`;
    return this.httpRequestService
      .post(url, body)
      .pipe(map((x: any) => this.utilitiesService.responseHandler(x)));
  }
}
